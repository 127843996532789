import Tools from "./Tools";

class FilterBar {
    constructor() {
        this.filterBar = $('[data-js="filter-bar"]');
        this._filterBarState = false;
        this.filterBarShow = $('[data-js="filter-bar-show"]');
        this.filterBarHide = $('[data-js="filter-bar-close"]');
        this.filterBarReset = $('[data-js="filter-bar-reset"]');
    }

    get filterBarState() {
        return this._filterBarState;
    }

    set filterBarState(value) {
        this._filterBarState = value;
    }

    events() {
        const _this = this;

        this.filterBarShow.on("click", function (event) {
            event.preventDefault();
            _this.filterBar.addClass("filter-bar--active");
            _this.filterBarState = true;
            Tools.backdropShow();
        });

        this.filterBarHide.on("click", function (event) {
            event.preventDefault();
            _this.filterBar.removeClass("filter-bar--active");
            _this.filterBarState = false;
            Tools.backdropHide();
        });

        this.filterBarReset.on("click", function (event) {
            event.preventDefault();
            window.location.assign("/zgloszenia/index");
        });

        $(document).on("click", function (event) {
            if (
                !$(event.target).closest(_this.filterBarShow).length > 0 &&
                !$(event.target).closest(_this.filterBar).length > 0
            ) {
                _this.filterBar.removeClass("filter-bar--active");
                Tools.backdropHide();
            }
        });
    }

    init() {
        this.events();
    }
}

export default FilterBar;
