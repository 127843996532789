class Alert {
    static showAlert(type = "info", message = "") {
        let html = `<div class="alert alert-${type} alert-dismissible fade show text-center mb-0 py-5" role="alert">
                        ${message}
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>`;

        $("#main-menu").prepend(html);
    }
}

export default Alert;
