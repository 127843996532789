(function($) {

//vars
var body = $('body');

function setGoogleMap() {

    $('.contact__seeonmap').on('click', function(e){

        e.preventDefault();

        $('html, body').stop().animate({
            'scrollTop': $('#googleMap').offset().top - 50
        }, 'slow', 'swing');
    
    });

}

//-- delay function for resizing
var delay = (function() {
    var timer = 0;
    return function(callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    }
})();

//set mobile menu to full height of window
var fitMobileMenu = function(){
    var window_height = $(window).height(),
        window_width = $(window).width();
    if( window_width < 992 ){
        $('#togglemenu').css('min-height', window_height);
    }else{
        $('#togglemenu').css('min-height', 'auto');
    }
}

//open-close mobile menu
var openCloseMenu = function(){
    $('.hamburger').click(function() {
        $('#togglemenu').toggleClass('menu__collapse--open');
    });
}

//set banner height
var setBanner = function(){
    var banner_container = $('.banner__container'),
        window_height = $(window).height(),
        window_width = $(window).width(),
        clients_height = 119,
        menu_height = 90;

    if( banner_container.length === 1 && window_width >= 1200 ){
        banner_container.css('height', window_height - clients_height - menu_height );
    }else{
        banner_container.css('height', 'auto');
    }
}

//map hover
var setMapTooltip = function(){
    $('.tooltip').tooltipster({
        theme: ['tooltipster-noir'],
        side: ['top'],
        animation: 'fade',
        contentAsHTML: true,
        contentCloning: true,
        distance:0,
        trigger:"custom",
        animationDuration: 300,
        delay:100,
        triggerOpen: {
            mouseenter: true,
            click: true,  // For mouse
            tap: true    // For touch device
        },
        triggerClose: {
            mouseleave:true,
            click: true,  // For mouse
            tap: true    // For touch device
        },
        functionPosition: function(instance, helper, position) {
            position.coord.top = position.coord.top + helper.geo.origin.size.height / 2;
            return position;
        }
    });
}

//home carousel 1
var setCarousel1 = function(){
    var carousel_1 = $('.carousel-1');
    carousel_1.slick({
        'arrows':false,
        'infinite':true,
        'mobileFirst':true,
    });

    $('#carousel-1-prev').on('click', function(){
        carousel_1.slick('prev');
    });

    $('#carousel-1-next').on('click', function(){
        carousel_1.slick('next');
    });
}

//home carousel 2
var setCarousel2 = function(){
    var carousel_2 = $('.carousel-2');
    carousel_2.slick({
        'arrows':false,
        'infinite':true,
        'mobileFirst':true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    variableWidth: true,
                    adaptiveHeight: false,
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    adaptiveHeight: true,
                }
            }
        ]
    });

    $('#carousel-2-prev').on('click', function(){
        carousel_2.slick('prev');
    });

    $('#carousel-2-next').on('click', function(){
        carousel_2.slick('next');
    });
}

//modules list scroll to fixed
var setFixedModulesMenu = function(){

    if ( $(window).width() < 1200) {
        
        $('.modules-menu').trigger('detach.ScrollToFixed');

        var m_btn = $('.modules-menu__btn'),
            m_menu = $('.modules-menu__list');

        m_menu.hide();

        m_btn.on('click', function(e){
            e.preventDefault();
            $(this).toggleClass('modules-menu__btn--open');
            if( $(this).hasClass('modules-menu__btn--open') ){
                m_menu.stop(true, true).slideDown(300);
            }else{
                m_menu.stop(true, true).slideUp(300);
            }
        });

    } else {
        $('.modules-menu').scrollToFixed({
            limit: function(){
                var calc_limit = $('.support').offset().top - $('#modules').outerHeight() - parseInt($('.modules').css('marginBottom'));

                console.log(calc_limit);

                var limit = Math.round(calc_limit);
                return limit;
            },
            unfixed: function(){
                $(this).css({
                    'left':15,
                    'bottom':0,
                    'top':'inherit'
                });
            }
        });
    }

}

//scroll to anchor
var scrollToTarget = function(){
    $('a.scroll-to[href^="#"]').on('click', function(e){
        e.preventDefault();

        var target = $(this).attr('href'),
            $target = $(target),
            offset = 15;

        if( $(window).width() < 1200 ){
            if( $('.modules-menu__btn').hasClass('modules-menu__btn--open') ){
                $('.modules-menu__btn').removeClass('modules-menu__btn--open');
                $('.modules-menu__list').slideToggle(300, function(){
                    if( $target.length > 0 ){
                        $('html, body').stop().animate({
                            'scrollTop': $target.offset().top - offset
                        }, 'slow', 'swing');
                    }
                });
            }
        }else{
            offset = 26;
            if( $target.length > 0 ){
                $('html, body').stop().animate({
                    'scrollTop': $target.offset().top - offset
                }, 'slow', 'swing');
            }
        }


    });
}

//show more realizations
var showMoreRealizations = function(){

    $('.btn-more-rls').on('click', function(e){

        e.preventDefault();

        var btn = $(this);

        if( btn.hasClass('btn-more-rls--open') ){
            $(this).parents('.modules__item').find('.modules-rls__more').slideDown(300, function(){
                btn.children('span').text('Zwiń');
                btn.removeClass('btn-more-rls--open');
                btn.addClass('btn-more-rls--close');
            });
        }else if( btn.hasClass('btn-more-rls--close') ){
            $(this).parents('.modules__item').find('.modules-rls__more').slideUp(300, function(){
                btn.children('span').text('Pokaż więcej');
                btn.removeClass('btn-more-rls--close');
                btn.addClass('btn-more-rls--open');
            });
        }

    });

}

var handleVideo = function(){

    var myvid = $('#video');

    $('#video, .video__play').click(function(){
        if (myvid[0].paused) {
            myvid[0].play()
            $('.video__play').addClass("hide")
        }else {
            myvid[0].pause();
           $('.video__play').removeClass("hide")
        }
    });

    
    if( myvid.length > 0 ){
        myvid[0].onplay = function() {
            myvid.next('.video__play').addClass('hide');
        };
        myvid[0].onpause = function() {
            myvid.next('.video__play').removeClass('hide');
        };
        myvid[0].onended = function() {
            myvid.next('.video__play').removeClass('hide');
        };
    }
}

$(document).ready(function(){

    scrollToTarget();

    fitMobileMenu();

    openCloseMenu();

    if( body.hasClass('is-home') ){
        setBanner();
        setMapTooltip();
        setCarousel1();
        setCarousel2();
    }

    if( body.hasClass('is-contact') ){
        setGoogleMap();
    }

    if( body.hasClass('is-modules') || body.hasClass('is-module') || body.hasClass('is-realizations') ){
        setFixedModulesMenu();
    }

    //show more realizations
    showMoreRealizations();

    handleVideo();

});

$(window).on('resize', function() {
    delay(function() {

        fitMobileMenu();

        if( body.hasClass('is-home') ){
            setBanner();
        }

        scrollToTarget();

    }, 100);
});

$(window).on('load', function() {
    var preloader = $('.page-preloader');
    $('body').removeClass('overflow-hidden').addClass('loaded');
    preloader.fadeOut(300, function(){
        preloader.remove();
    });
});

}(jQuery));

//AOS.init({
//    startEvent: 'load',
//});