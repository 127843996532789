$(function () {
    if ($('body').hasClass('is-home')) {
        var logo = new Image();
        logo.src = $('#main-menu .logo img.logo__img').data('other');
        $('.page').css('padding-top', 0);
        $(window).scroll(function () {
            if ($(window).scrollTop() > 0) {
                $('body').removeClass('is-home');
                $('#main-menu .logo img.logo__img').attr('src', $('#main-menu .logo img.logo__img').data('other'));
            } else {
                $('body').addClass('is-home');
                $('#main-menu .logo img.logo__img').attr('src', $('#main-menu .logo img.logo__img').data('home'));
            }
        });

        $(window).resize(function () {
            $('.banner__container').css('min-height', $(window).height() - $('.clients').height());
        });

        $(window).on("load", function () {
            var client_box_width = $(".clients__list").width();
            var logotypes_width = 0;
            $(".clients__list img").each(function (index) {
                logotypes_width += parseInt($(this).outerWidth(true), 10);
            });


            function runAnimation() {
                $(".clients__list img:first").animate({
                    marginLeft: '-=' + (logotypes_width + 7) + 'px'
                }, logotypes_width / client_box_width * 10000,
                        'linear',
                        function () {
                            $(".clients__list img:first").css("margin-left", "");
                            runAnimation();
                        });
            }

            $(".clients__list img").clone().appendTo(".clients__list");


            $('.clients__list').hover(function () {
                $('.clients__list img:first').stop();
            }, function () {
                runAnimation();
            });
            runAnimation();
        });
    }
    $(window).on("load", function () {
        var hash = location.hash;
        if (hash) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $(hash).offset().top - $("#main-menu").height() - 5
            }, 200);
        }
    });

    if ($.magnificPopup != undefined) {
        $(".mceContentBody").magnificPopup({
            delegate: 'a[data-lightbox=lightbox]', // child items selector, by clicking on it popup will open
            type: 'image',
            gallery: {
                enabled: true,
                tPrev: 'Poprzednie', // title for left button
                tNext: 'Następne', // title for right button
                tCounter: '<span class="mfp-counter">%curr% z %total%</span>' // markup of counter
            },
            closeMarkup: 'Zamknij'
        });
    }
    
    var messageError = $("#messageError").text().trim();
    if (messageError.length) {
        ga("send", "event", "error", "display", messageError);
    }

    var messageWarning = $("#messageWarning").text().trim();
    if (messageWarning.length) {
        ga("send", "event", "warning", "display", messageWarning);
    }

    var messageInfo = $("#messageInfo").text().trim();
    if (messageInfo.length) {
        ga("send", "event", "info", "display", messageInfo);
    }
});
