// Custom styles
import "./scss/app.scss";

// Custom scripts
import Tools from "./js/modules/Tools";
import Countdown from "./js/modules/Countdown";
import FilterBar from "./js/modules/FilterBar";
import "./js/app";
import "./js/iksoris";

(() => {
    if (typeof $ === "undefined") {
        throw new TypeError("JQuery library is required!");
    }

    // Countdown
    const sessionTimer = $("#session-timer");
    const countdown = new Countdown(sessionTimer, {
        time: [0, 0, 0, 0, 0, 5, 0],
        afterTime: function () {
            sessionTimer.addClass("session-timer--active");
        },
        beforeTime: function () {
            sessionTimer.removeClass("session-timer--active");
        },
    });

    countdown.init();

    // TinyMCE
    if (
        [
            "strefa_klienta_zgloszenia_szczegoly",
            "strefa_klienta_zgloszenia_dodaj",
        ].includes(Tools.getCurrentPageName())
    ) {
        tinymce.init({
            selector: "textarea",
            menubar: false,
            statusbar: false,
            language: "pl",
            language_url: "/js/tinymce/langs/pl.js",
            mobile: {
                menubar: false,
            },
        });
    }

    // Filter bar
    if (
        ["strefa_klienta_zgloszenia_index"].includes(Tools.getCurrentPageName())
    ) {
        const filterBar = new FilterBar();

        filterBar.init();
    }
})();

// Images
const requireContext = require.context(
    "./img/",
    true,
    /\.(gif|png|jpe?g|svg)$/i
);

requireContext.keys().map(requireContext);
