import Alert from "./Alert";

class Countdown {
    constructor(timer, config) {
        this.timer = timer;
        this.config = config;
        this._remainingTime = {
            node: null,
            value: null,
            action: null,
        };
    }

    get remainingTime() {
        return this._remainingTime;
    }

    set remainingTime(objectLiteral) {
        Object.assign(this._remainingTime, objectLiteral);

        if (
            this.remainingTime.node &&
            this.remainingTime.value &&
            this.remainingTime.action
        ) {
            this.setTimer();
        }
    }

    events() {
        const _this = this;

        this.timer.on("click", function (event) {
            event.preventDefault();

            _this.getRemainingTime("renew");
        });
    }

    getRemainingTime(action) {
        const _this = this;

        $.ajax({
            url: "/sesja/index",
            method: "GET",
            data: {
                time_to: action,
            },
            success: function (response) {
                const responseObject = JSON.parse(response);

                if (responseObject.status === "success") {
                    _this.remainingTime = {
                        value: responseObject.timeTo,
                        action: action,
                    };
                } else if (responseObject.status === "error") {
                    Alert.showAlert("danger", responseObject.message);
                    console.error(responseObject);
                }
            },
            error: function (xhr, status, error) {
                console.error(status);
                console.error(error);
            },
        });
    }

    setTimer() {
        const _this = this;

        if (this.remainingTime.action === "get") {
            this.remainingTime.node.countdown({
                until: "+" + this.remainingTime.value,
                format: "MS",
                compact: true,
                expiryUrl: window.location.origin,
                onTick: function (periods) {
                    _this.toggleTimerVisibility(periods);
                },
            });
        } else if (this.remainingTime.action === "renew") {
            this.remainingTime.node.countdown("option", {
                until: "+" + this.remainingTime.value,
            });
        }
    }

    toggleTimerVisibility(periods) {
        if (
            $.countdown.periodsToSeconds(periods) <=
            $.countdown.periodsToSeconds(this.config.time)
        ) {
            this.config.afterTime();
        } else {
            this.config.beforeTime();
        }
    }

    init() {
        if (Object.keys(this.timer).length > 0) {
            this.events();
            this.remainingTime = {
                node: this.timer.find(".session-timer__remaining-time"),
            };
            this.getRemainingTime("get");
        }
    }
}

export default Countdown;
